var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fQsxmtlr_hEcH66A-bGIl"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { captureConsoleIntegration, httpClientIntegration } from "@sentry/integrations";
import env from "@lib/utils/env";

const SENTRY_DSN = process.env.SENTRY_DSN || env("SENTRY_DSN");
const RELEASE_SHA = process.env.RELEASE_SHA || env("RELEASE_SHA");
const SENTRY_ENVIRONMENT = process.env.NODE_ENV || env("NODE_ENV");
const OMIT_LOGS = process.env.OMIT_LOGS || env("OMIT_LOGS");

const omitLogs = OMIT_LOGS === "true" || OMIT_LOGS === true;

if (omitLogs === false) {
  console.log("Initialising sentry - client");
  Sentry.init({
    environment: SENTRY_ENVIRONMENT || "development",
    dsn: SENTRY_DSN || "https://3e4db71ed3524775ab75445ac10d349f@o419357.ingest.sentry.io/5985473",
    integrations: [captureConsoleIntegration({ levels: ["error"] }), httpClientIntegration()],
    includeLocalVariables: true,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampler: (samplingContext: any) => {
      if (samplingContext?.transactionContext?.name === "/") {
        return 0;
      }
      return (SENTRY_ENVIRONMENT || "development") !== "development";
    },
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
    sendDefaultPii: true,
    allowUrls: [
      "sourceful.com",
      "snyf.io",
      "cdn.sanity.io",
      "storage.googleapis.com",
      "launchdarkly.com",
    ],
    beforeSend(event, hint) {
      const error = hint.originalException as Error;
      if (
        error?.name === "LaunchDarklyFlagFetchError" ||
        error?.message?.includes("ResizeObserver loop completed with undelivered notifications")
      )
        return null;
      return event;
    },

    release: RELEASE_SHA,

    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
